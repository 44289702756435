<template>
  <div class="relative flex flex-col w-1/2 gap-2 signature-field mobile-full-width">
    <div class="flex print-w-full">
        <div class="show-print" v-if="!notInsideBlock">
          <div><b>Applicant Name:</b></div>
          {{ personalDetails?.first_name }} {{ personalDetails?.last_name }}
        </div>
        <div class="signature-pad">
          <span class="show-print" :class="{'text-center':!notInsideBlock}"><b>Signature:</b></span>  
          <div class="relative signature-cnt">
          <VueSignaturePad
            :value="getValue.value"
            ref="signaturePad"
            width="100%"
            class="
              form-control
              max-w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-dotted
              rounded-xl
              transition
              ease-in-out
              m-0
              resize-y
              focus:text-gray-700
              focus:bg-white
              focus:border-blue-600
              focus:outline-none
              signature-pad1
            "
            rows="3"
            :class="{
              'border-box-stroke': !error,
              'border-red-600': error,
              'pointer-events-none': previewForm,
              'cursor-not-allowed' : disabled,
            }"
            :options="{ onEnd, onBegin}"
            @input="handleChange"
          />
          <button @click="clear" class="absolute top-5 right-5 mobile-visible no-print" :class="{hideBtn : previewForm}">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white"/>
                <path d="M11.8858 12.6665L13.5761 14.3568L12.6333 15.2996L9.3335 11.9998L12.6333 8.69995L13.5761 9.64276L11.8858 11.3331H16.6668C19.6124 11.3331 22.0002 13.7209 22.0002 16.6665C22.0002 19.612 19.6124 21.9998 16.6668 21.9998H10.6668V20.6665H16.6668C18.876 20.6665 20.6668 18.8756 20.6668 16.6665C20.6668 14.4573 18.876 12.6665 16.6668 12.6665H11.8858Z" fill="#910000"/>
                <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#910000"/>
              </svg>
          </button>
        </div>
        </div>
         <div class="show-print w-2/12" v-if="!notInsideBlock">
          <div ><b>Date:</b></div>
          {{ convertedSignDate }}
        </div>
    </div>
    <div class="flex justify-between">
      <div>
        <!--{{ userData ? userData.name : null  }}-->
      </div>
      <div class="flex gap-3 mt-2 self-end print--none mobile-d-none">
        <button
          v-if="currValue"
          @click="clear"
          title="Clear signature"
          class="cursor-pointer h-7 border border-gray-700 w-20 min-w-min px-3 hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center disabled:opacity-50"
        >
          Clear
        </button>
        <button
          v-if="currValue"
          @click="undo"
          title="Undo"
          class="cursor-pointer h-7 border border-gray-700 w-20 min-w-min px-3 hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center disabled:opacity-50"
        >
          Undo
        </button>
      </div>
    </div>
    <div class="flex flex- items-center justify-between">
      <div class="flex  items-center">
        <template  v-if="ip && showIp && currValue">
          <span class="ml-2">
            {{ ip }}
          </span>
        </template>
      </div>

      <div class="flex justify-end  w-1/2 ml-3 w-max signature-data mobile-d-none">
        <template  v-if="signDate">
          {{ signDate }}
        </template>
      </div>

    </div>
    <div class="flex items-center mobile-visible-flex" :class="{hideBtn : previewForm}">
      <hr class="w-5/12 border border-black-500">
      <div class="w-2/12 text-center">or</div>
      <hr class="w-5/12 border border-black-500">
    </div>
    <div class="mobile-visible no-print text-center" :class="{hideBtn: previewForm}">      
      <label class="file-upload inline-block">
        <div
          class="h-9 w-full bg-white text-blue-700 border border-blue-500 rounded flex items-center justify-center rounded-md"
        >
        <span class="flex">
          <svg class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.83317 15.8333H17.1665V10H18.8332V16.6667C18.8332 17.1269 18.4601 17.5 17.9998 17.5H2.99984C2.5396 17.5 2.1665 17.1269 2.1665 16.6667V10H3.83317V15.8333ZM11.3332 7.5V13.3333H9.6665V7.5H5.49984L10.4998 2.5L15.4998 7.5H11.3332Z" fill="#0D69D5"/></svg>
          Upload
        </span>
      </div>
        <input type="file" ref="fileInput" @change="handleFileUpload" class="hidden">
      </label>
    </div>
    <div class="text-blue-500 flex items-center mobile-visible-flex">
      <Loader class="w-0.1 h-0.1 grey-loader mr-2" v-if="showloader"/>
      <svg v-if="!showloader && selectedFileName" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.88584 4.17144L5.11457 7.9427C4.85422 8.20304 4.85422 8.6251 5.11457 8.8855C5.37492 9.14584 5.79704 9.14584 6.05737 8.8855L9.82864 5.11424C10.6096 4.3332 10.6096 3.06687 9.82864 2.28582C9.04757 1.50477 7.78124 1.50477 7.00017 2.28582L3.22895 6.05704C1.92721 7.35877 1.92721 9.46937 3.22895 10.7711C4.5307 12.0728 6.64124 12.0728 7.94297 10.7711L11.7142 6.99984L12.657 7.9427L8.88584 11.7139C7.06337 13.5364 4.10859 13.5364 2.28615 11.7139C0.4637 9.89144 0.4637 6.9367 2.28615 5.11424L6.05737 1.34301C7.35911 0.0412625 9.46971 0.0412625 10.7714 1.34301C12.0732 2.64476 12.0732 4.75531 10.7714 6.05704L7.00017 9.8283C6.21917 10.6094 4.95281 10.6094 4.17177 9.8283C3.39071 9.04724 3.39071 7.7809 4.17177 6.99984L7.94297 3.22863L8.88584 4.17144Z" fill="#0D69D5"/></svg>
      <div class="flex justify-between w-full ml-2 items-center">
        {{ selectedFileName }}
        <svg width="14" height="14" v-if="selectedFileName" @click="clear" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.3334 3.00016H13.6667V4.3335H12.3334V13.0002C12.3334 13.3684 12.0349 13.6668 11.6667 13.6668H2.33337C1.96519 13.6668 1.66671 13.3684 1.66671 13.0002V4.3335H0.333374V3.00016H3.66671V1.00016C3.66671 0.631976 3.96519 0.333496 4.33337 0.333496H9.66671C10.0349 0.333496 10.3334 0.631976 10.3334 1.00016V3.00016ZM11 4.3335H3.00004V12.3335H11V4.3335ZM5.00004 1.66683V3.00016H9.00004V1.66683H5.00004Z" fill="black" fill-opacity="0.32"/></svg>
      </div>
    </div>
    <ProgressBar v-show="showloader"/>
  </div>
</template>

<script>
import Vue from "vue";
import { DateTime } from "luxon";
import VueSignature from "vue-signature-pad";
import axios from "@/axios"
import { isJson } from '../../utils';
import sections from "../../store";
import Loader from "@shared/loader";
import ProgressBar from "@shared/progress-bar";
Vue.use(VueSignature);

export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    // is used to prview filled form before its submitting:
    previewForm: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      default: () => { }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notInsideBlock: Boolean,
    formAlreadySubmitted: {
      type: Boolean,
    }
  },
  components:{
    Loader,
    ProgressBar
  },
  data() {
    return {
      currValue: undefined,
      token:null,
      ip: '',
      loader: false,
      canvasDimensions:{
        offsetWidth:null,
        offsetHeight:null
      },
      selectedFileName: null,
      base64Image: null,
      showloader:false,
      hasRunCode: false,

    }
  },
  watch: {
    // Redraw placeholder if the signature value changes
    getValue() {
      this.drawPlaceholder();
    }
  },
  computed: {
    personalDetails() {
      return sections.personal_details;
    },
    getValue: {
      get() {
        if (isJson(this.fieldData.value)) {
          const result = JSON.parse(this.fieldData.value);
          return result;
        }
        return {};
      },
      set(newValue) {
        if (!newValue){
          this.$emit("input", null);
          this.$emit("handleFieldValueChnage", null, 'signed');
          return;
        }
        const result = {
          value: newValue,
          date: newValue ? Date.now() : '',
          ip: this.showIp ? this.ip : null
        }
        this.$emit("input", JSON.stringify(result));
        this.$emit("handleFieldValueChnage", result, 'signed');
      },
    },
    showIp() {
      return this.fieldData && this.fieldData.meta && JSON.parse(this.fieldData.meta)?.get_ip_address || false;
    },
    signDate() {
      return this.getValue.date ?
        DateTime.fromJSDate(new Date(this.getValue.date)).toFormat("MM/dd/yyyy, hh:mm:ss a") :
        '';
    },
    convertedSignDate() {
      return this.getValue.date ?
        DateTime.fromJSDate(new Date(this.getValue.date)).toFormat('dd LLL yyyy') :
        '';
    },
  },
  mounted() {
    let canvas = this.$refs.signaturePad?.$refs?.signaturePadCanvas;
    this.canvasDimensions.offsetWidth = canvas.offsetWidth;
    this.canvasDimensions.offsetHeight = canvas.offsetHeight;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.$refs.signaturePad.fromDataURL(this.getValue.value);
    if (this.disabled || (this.getValue.value && window?.innerWidth<769) || this.formAlreadySubmitted)
      this.$refs.signaturePad.lockSignaturePad();
    this.token = this.$route.query.access_token;
    this.ip = this.getValue?.ip || "";
    this.currValue = this.getValue.value;
    this.fetchIpAddress();
    this.drawPlaceholder()
  },
  updated(){
    if (!this.hasRunCode) {
      if(JSON.stringify(this.getValue.value)==='{}' || !this.getValue.value){
        this.$emit("input", null);
      }
    this.hasRunCode = true;
    }
    if(this.selectedFileName){
      setTimeout(() => {
          this.showloader=false;
        }, 1300)
    }
  },
  methods: {
    handleResize() {
      // Save the signature data before resizing
      this.modifySignatureCanvas()
      this.$refs.signaturePad?.fromDataURL(this.getValue.value);
    },
    modifySignatureCanvas() {
      let canvas = document.querySelector("canvas");
      let ratio =  window.devicePixelRatio;
      canvas.width = this.canvasDimensions.offsetWidth * ratio;
      canvas.height = this.canvasDimensions.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
    },
    async fetchIpAddress() {
      // this.handleChange(value)
      this.loader = true
      if (this.ip === '') {
        try {
          const { data } = await axios.get(`user-auth/get-ip`, {
            headers: {
              'Authorization':`Bearer ${this.token}`
            },
          })
          this.ip = data.ip || ''
        } catch (error) {
          console.log('error :>> ', error);
        }
      }
      // this.getValue = value
      let result = {
        ...this.getValue,
        ip: this.showIp ? this.ip : null
        
      }
      this.$emit("input", JSON.stringify(result));
      this.loader = false
    },
    handleChange({ target }) {
      this.getValue = target.value;
    },
    undo() {
      this.modifySignatureCanvas();
      this.$refs.signaturePad.undoSignature();
      this.onEnd();
    },
    clear() {
      let canvas = document.querySelector("canvas");
      canvas.getContext("2d").scale(1.5, 1.5);
      this.$refs.signaturePad.clearSignature();
      this.modifySignatureCanvas();
      this.onEnd();
      this.selectedFileName="";
      this.$refs.fileInput.value="";
      this.$refs.signaturePad.openSignaturePad()
      this.showloader=false;
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.currValue = data;
      this.getValue = data;
    },
    readFileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    async handleFileUpload() {
      const fileInput = this.$refs.fileInput;
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (fileInput.files.length > 0) {
        if(allowedTypes.includes(fileInput.files[0].type)){
          this.showloader=true;
          this.selectedFileName = this.truncateString(fileInput.files[0].name);
          this.selectedFile = fileInput.files[0];
          if (this.selectedFile) {
            await this.readFileAsDataURL(this.selectedFile)
              .then((base64Data) => {
                this.base64Image = base64Data;
              })
              .catch((error) => {
                console.error('Error reading file:', error);
              });
            this.getValue = this.base64Image
            this.currValue = this.base64Image
            const canvas = this.$refs.signaturePad.$refs.signaturePadCanvas
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src =  this.base64Image;
            img.onload = function() {
              ctx.drawImage(img, 0, 0,canvas.offsetWidth ,canvas.offsetHeight );
            }
            this.$refs.signaturePad.lockSignaturePad();
            this.$emit('handleFieldValueChnage', this.fieldData, 'uploaded');
          }
          else {
            this.selectedFileName = null;
          }
        }
        else{
          this.$toast.error("Invalid file type. Please upload a JPEG, PNG file.")
        }
      }
    },
    truncateString(str) {
      return (str.length <= 2) ? str : str.slice(0, 20)+"...."
    },
    onBegin() {
      this.removePlaceholder(); 
    },
    removePlaceholder() {
      let canvas = document.querySelector("canvas"); 
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height); 
    },
    drawPlaceholder() {
      let canvas = document.querySelector("canvas");
      const ctx = canvas.getContext('2d');

      ctx.font = '16px Arial';
      ctx.fillStyle = 'gray';

      if (Object.keys(this.getValue).length === 0)
      {
        const width = canvas.width;
        const height = canvas.height;
        const textWidth = ctx.measureText(this.fieldData?.placeholder || '').width;
        const x = (width - textWidth) / 2; 
        const y = height / 4; 

        ctx.fillText(this.fieldData?.placeholder || '', x, y);
      }
    },
  }
}
</script>

<style>
  .grey-loader{
    width: 20px;
    height: 20px;
    border-width: 3px !important;
    border-color: gray;
    border-bottom-color: white;
  }
  .mobile-visible-flex{
      display: none;
    }
  @media screen and (min-width: 769px){
    .mobile-visible{
      display: block;
    }
    .mobile-d-none{
      display: none;
    }
    .mobile-visible-flex{
        display: flex;
      }
    .signature-pad{
        width:100%;
      }
    .file-upload{
      width: 100%;
    }
    .signature-cnt{
      height: 12rem;
    }
    .signature-field{
      width: 30rem;
    }
  }
  @media screen and (max-width: 768px){
    .mobile-full-width,.signature-pad{
      width:100%;
    }
    .mobile-d-none{
      display: none;
    }
    .mobile-visible{
      display: block;
    }
    .mobile-visible-flex{
      display: flex;
    }
    .file-upload{
      width: 100%;
    }
    .signature-cnt{
      height: 10rem;
    }
  }
  @media screen and (min-width: 550px) and (max-width: 1080px) and (orientation: landscape) {
    .signature-pad {
      width: 100%;
    }
  }

  .hideBtn{
    display:none
  }
</style>